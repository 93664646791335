var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"dISgE07OZA5MPgqSzqeEE"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { sentryConfigShared } from './sentry.config';

Sentry.init({
    ...sentryConfigShared,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.01,
    integrations: [new Sentry.GlobalHandlers({ onerror: false, onunhandledrejection: false })],
});
